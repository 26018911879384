<template>
  <div class="container">
    <div class="page-sub-box ewm">
      <div class="locker_setting_list sub_new_style01 sub_ui_box1">
        <div class="page_search_box line_bottom_1px">
          <div class="flex space-x-2 inner">
            <div class="mt-2">휴가일</div>
            <div>
              <DxDateBox
                styling-mode="outlined"
                width="120"
                height="30"
                v-model="searchType.customTypes.dayStart"
                type="date"
                dateSerializationFormat="yyyyMMdd"
                display-format="yyyy.MM.dd"
                dateOutOfRangeMessage="종료일은 시작일보다 크거나 같아야 합니다."
                :max="searchType.customTypes.dayEnd"
                maxLength="10"
                invalid-date-message="입력 데이터가 유효하지 않습니다."
              >
                <DxValidator validation-group="validationSearch">
                  <DxRequiredRule message="기준년월은 필수입니다." />
                </DxValidator>
              </DxDateBox>
            </div>
            <div class="mt-1">~</div>
            <div>
              <DxDateBox
                styling-mode="outlined"
                width="120"
                height="30"
                v-model="searchType.customTypes.dayEnd"
                type="date"
                dateSerializationFormat="yyyyMMdd"
                display-format="yyyy.MM.dd"
                dateOutOfRangeMessage="종료일은 시작일보다 크거나 같아야 합니다."
                maxLength="10"
                :min="searchType.customTypes.dayStart"
                invalid-date-message="입력 데이터가 유효하지 않습니다."
              >
                <DxValidator validation-group="validationSearch">
                  <DxRequiredRule message="기준년월은 필수입니다." />
                </DxValidator>
              </DxDateBox>
            </div>

            <DxButton text="검색" class="btn_M box-btn-search" type="button" :height="30" @click="selectDataList" />
          </div>
        </div>
      </div>

      <div class="flex relative">
        <div class="flex space-x-4 absolute z-50 right-32 top-8">
          <div class="text-lg">{{ initData.vacationYear }} 잔여휴가 일수: {{ initData.leastVacation }}</div>
          <div class="text-lg">사용 휴가 일수 : {{ initData.vacationDayoff }}</div>
        </div>
        <div>
          <esp-dx-data-grid
            :data-grid="dataGrid"
            ref="personalVacationGrid"
            @selection-changed="onSelectionChanged"
            @row-click="onRowClick"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { DxButton } from 'devextreme-vue/button';
  import { DxDateBox } from 'devextreme-vue/date-box';
  import { DxValidator, DxRequiredRule } from 'devextreme-vue/validator';
  import validationEngine from 'devextreme/ui/validation_engine';
  import { getPastFromToday, isSuccess } from '@/plugins/common-lib';
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid.vue';

  let vm = this;

  export default {
    components: {
      EspDxDataGrid,
      DxButton,
      DxDateBox,

      DxRequiredRule,
      DxValidator,
    },
    data() {
      return {
        canEdit: true,
        searchType: {
          obj: {},
          defaultObj: { id: 'ROOT', codeValue: 'ROOT', codeNm: '전체' },
          defaultValue: 'ROOT',
          customTypes: {
            dayStart: getPastFromToday(6, 'months'),
            dayEnd: getPastFromToday(-6, 'months'),
          },
          paramsData: null,
        },
        codes: {
          vacationTypePtCd: {
            dataSource: [],
            displayExpr: 'codeNm',
            valueExpr: 'codeId',
          },
          vacationTypeCd: {
            dataSource: [],
            displayExpr: 'codeNm',
            valueExpr: 'codeId',
          },
        },
        initData: [],
        dataGrid: {
          keyExpr: 'id',
          refName: 'personalVacationGrid',
          allowColumnResizing: true, //컬럼 사이즈 허용
          columnResizingMode: 'widget',
          columnAutoWidth: true,
          showBorders: false, //border 유무
          showColumnHeaders: true, //컬럼 헤더 유무
          showColumnLines: false, //컬럼 세로선 유무
          showRowLines: true, //컬럼 가로선 유무
          rowAlternationEnabled: false,
          dataSource: [],
          // width:'200',     // 주석처리시 100%
          // height:'500',    // 주석처리시 100%
          scrolling: {
            mode: 'standard',
          },
          callApi: 'CALL_EWM_API',
          apiActionNm: {},
          customEvent: {
            rowClick: true,
            selectionChanged: true,
          },
          showActionButtons: {
            customButtons: [
              {
                widget: 'dxButton',
                options: {
                  icon: '',
                  text: '휴가신청',
                  elementAttr: { class: 'btn_XS default filled' },
                  width: 75,
                  height: 30,
                  onClick() {
                    vm.onAddVacation();
                  },
                },
                location: 'before',
              },
              {
                widget: 'dxButton',
                options: {
                  icon: '',
                  text: '재기안',
                  elementAttr: { class: 'btn_XS default filled' },
                  width: 60,
                  height: 30,
                  disabled: this.canEdit,
                  ref: 'changeButton',
                  onClick() {
                    vm.onRetryData();
                  },
                },
                location: 'before',
              },
              {
                widget: 'dxButton',
                options: {
                  icon: '',
                  text: '삭제',
                  elementAttr: { class: 'btn_XS white light_filled trash' },
                  width: 60,
                  height: 30,
                  onClick() {
                    vm.onDeleteData();
                  },
                },
                location: 'before',
              },
            ],
          },
          grouping: {
            contextMenuEnabled: false,
            autoExpandAll: false,
            allowCollapsing: true,
            expandMode: 'rowClick', // rowClick or buttonClick
          },
          groupPanel: {
            visible: false,
          },
          columnChooser: {
            enabled: false, // 컬럼 Chooser 버튼 사용유무
          },
          loadPanel: {
            enabled: true, // 로딩바 표시 유무
          },
          sorting: {
            mode: 'multiple', // single multiple
          },
          remoteOperations: {
            // 서버사이드 여부
            filtering: false,
            sorting: false,
            grouping: false,
            paging: false,
          },
          paging: {
            enabled: false,
            pageSize: 10,
            pageIndex: 0, // 시작페이지
          },
          pager: {
            visible: false, //페이저 표시 여부
            showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [],
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          filterRow: {
            visible: true,
            operationDescriptions: {
              contains: '포함',
            },
          },
          headerFilter: {
            visible: true,
          },
          editing: {
            allowUpdating: false, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
            allowDeleting: false,
            mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
            startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
            selectTextOnEditStart: false, //셀 수정시 텍스트 전체 선택 여부
          },
          selecting: {
            mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
            selectAllMode: 'allPages', //행 선택 허용 범위 : ['allPages', 'page']
            showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          },
          columns: [
            {
              caption: '휴가구분',
              dataField: 'vacationTypePtCd',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowHeaderFiltering: false,
            },
            {
              caption: '휴가구분상세',
              dataField: 'vacationTypeCd',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowHeaderFiltering: false,
            },
            {
              caption: '휴가일자',
              dataField: 'vacationStartYmd',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowHeaderFiltering: false,
              calculateCellValue: rowData => {
                // if (rowData.vacationStartYmd == rowData.vacationEndYmd) {
                // 	return `${rowData.vacationEndYmd}`;
                // } else {
                return `${rowData.vacationStartYmd} ~ ${rowData.vacationEndYmd}`;
                // }
              },
            },
            {
              caption: '휴가시간',
              dataField: 'vacationStartTime',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowHeaderFiltering: false,
              calculateCellValue: rowData => {
                if (rowData.vacationStartTime) {
                  return `${this.$_commonlib.formatDate(rowData.vacationStartTime, 'HHmm', 'HH:mm')} ~ ${this.$_commonlib.formatDate(
                    rowData.vacationEndTime,
                    'HHmm',
                    'HH:mm',
                  )}`;
                }
              },
            },
            {
              caption: '차감 휴가 수',
              dataField: 'vacationDayoff',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowHeaderFiltering: false,
            },
            {
              caption: '최종승인여부',
              dataField: 'finalApprovalStatus',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowHeaderFiltering: false,
              lookup: {
                dataSource: this.$_enums.ewm.approvalStatus.values,
                displayExpr: 'label',
                valueExpr: 'value',
              },
              // lookup: {
              // 	dataSource: [
              // 		{
              // 			codeId: 'PENDING',
              // 			codeNm: '대기',
              // 		},
              // 		{
              // 			codeId: 'IN_PROGRESS',
              // 			codeNm: '진행중',
              // 		},
              // 		{
              // 			codeId: 'APPROVAL',
              // 			codeNm: '승인',
              // 		},
              // 		{
              // 			codeId: 'APPROVAL_CANCEL',
              // 			codeNm: '승인취소',
              // 		},
              // 		{
              // 			codeId: 'RE_SUBMISSION',
              // 			codeNm: '재결재',
              // 		},
              // 		{
              // 			codeId: 'FORCE_APPROVAL',
              // 			codeNm: '전결',
              // 		},
              // 		{
              // 			codeId: 'REJECTED',
              // 			codeNm: '반려',
              // 		},
              // 		{
              // 			codeId: 'REVOKED',
              // 			codeNm: '결재취소',
              // 		},
              // 	],
              // 	displayExpr: 'codeNm',
              // 	valueExpr: 'codeId',
              // 	allowClearing: true,
              // },
            },
            {
              caption: '상세보기',
              dataField: '',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowHeaderFiltering: false,
              sortOrder: 'none',
              cellTemplate: (container, options) => {
                const button = new DxButton({
                  propsData: {
                    elementAttr: { class: 'btn_XS white light_filled mr-0' },
                    text: '상세',
                    width: 80,
                    height: 30,
                    onClick: () => {
                      vm.showVacation(options.data);
                    },
                  },
                });

                button.$mount();
                container.append(button.$el);
              },
            },
          ],
        },
      };
    },
    computed: {},
    methods: {
      onAddVacation() {
        let params = { updateYn: false, reSubmission: true, initData: this.initData };
        this.$store.commit('setDetailParams', params);
        this.$router.push({ path: '/ewm/personal/vacation/vacation-detail' });
      },
      showVacation(data) {
        let params = { updateYn: true, reSubmission: true, initData: this.initData, formData: data };
        this.$store.commit('setDetailParams', params);
        this.$router.push({ path: '/ewm/personal/vacation/vacation-detail' });
      },
      /** @description : 재기안 */
      onRetryData() {
        console.log('재기안');
        const selectedRowsData = this.$refs.personalVacationGrid.getGridInstance.getSelectedRowsData();
        if (!selectedRowsData?.length) {
          this.$_Msg(this.$_msgContents('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
          return;
        }
        if (selectedRowsData.length > 1) {
          this.$_Msg('하나만 선택해 주세요.');
          return;
        }
        if (
          selectedRowsData[0].finalApprovalStatus == 'APPROVAL_CANCEL' ||
          selectedRowsData[0].finalApprovalStatus == 'REJECTED' ||
          selectedRowsData[0].finalApprovalStatus == 'REVOKED' ||
          selectedRowsData[0].finalApprovalStatus == 'PENDING'
        ) {
          let params = { updateYn: true, reSubmission: false, initData: this.initData, formData: selectedRowsData[0] };
          this.$store.commit('setDetailParams', params);
          this.$router.push({ path: '/ewm/personal/vacation/vacation-detail' });
        } else {
          this.$_Msg('대기, 반려, 취소일 경우에만 재기안이 가능합니다.');
          return;
        }
      },
      async onDeleteData() {
        const selectedRowsData = this.$refs.personalVacationGrid.getGridInstance.getSelectedRowsData();
        if (!selectedRowsData?.length) {
          this.$_Msg(this.$_msgContents('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
          return;
        }

        const allowChecking = selectedRowsData.every(
          cd =>
            cd.finalApprovalStatus === 'PENDING' ||
            cd.finalApprovalStatus === 'APPROVAL_CANCEL' ||
            cd.finalApprovalStatus === 'REJECTED' ||
            cd.finalApprovalStatus === 'REVOKED',
        );

        if (!allowChecking) {
          this.$_Msg('대기, 반려, 취소일 경우에만 삭제가 가능합니다.');
          return;
        }

        const sendData = selectedRowsData.map(x => x.id); //보낼 데이터

        if (!(await this.$_Confirm(this.$_msgContents('CMN_CFM_DUPLICATE_SELECTED'), { title: `<h2>알림</h2>` }))) {
          return;
        }

        const payload = {
          actionname: 'EWM_PERSONAL_VACATION_DELETE',
          data: sendData,
          loading: true,
        };

        const res = await this.CALL_EWM_API(payload);

        if (isSuccess(res)) {
          this.$_Toast(this.$_msgContents('CMN_SUC_DELETE'));
          this.selectDataList();
          this.selectCountList();
        } else {
          this.$_Toast(this.$_msgContents('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
        }
      },
      onRowClick(e) {
        this.$refs.personalVacationGrid.getGridInstance.selectRows([e.data.id], false);
      },
      onSelectionChanged(e) {
        const changeButton = this.$refs.changeButton;
        // console.log(changeButton);
        if (e.selectedRowsData.length > 1) {
          if (changeButton) {
            this.canEdit = false;
          }
        }
      },
      /** @description : 데이터 검색 메서드 */
      async selectDataList() {
        if (!validationEngine.validateGroup('validationSearch').isValid) {
          return;
        }

        let startDt = this.$_commonlib.formatDate(vm.searchType.customTypes.dayStart.slice(0, 6) + '01', 'YYYYMMDD', 'YYYY-MM-DD');
        let endDt = this.$_commonlib.formatDate(vm.searchType.customTypes.dayEnd.slice(0, 6) + '30', 'YYYYMMDD', 'YYYY-MM-DD');

        const payload = {
          actionname: 'EWM_PERSONAL_VACATION',
          data: {
            startDt: startDt,
            endDt: endDt,
          },
          loading: false,
        };

        const res = await vm.CALL_EWM_API(payload);

        if (isSuccess(res)) {
          this.dataGrid.dataSource = res.data.data;
          this.dataGrid.dataSource.forEach(d => {
            let filterParent = this.codes.vacationTypeCd.dataSource.find(v => v.codeId === d.vacationTypeCd).parentId;
            d.vacationTypePtCd = filterParent;
          });
        }
      },
      async selectCountList() {
        const payload = {
          actionname: 'EWM_PERSONAL_VACATION_COUNT',
          loading: false,
        };
        const res = await vm.CALL_EWM_API(payload);
        if (isSuccess(res)) {
          this.initData = res.data.data[0];
          this.initData.leastVacation =
            res.data.data[0].vacationNumber + res.data.data[0].vacationEditNumber - res.data.data[0].vacationDayoff;
        }
      },
      /** @description : 셀렉트 박스 초기화 */
      async initCodeMap() {
        this.codeMap = await this.$_getCodeMapList('root_ewm_hr_vacation_division');
      },
      /** @description : 라이프사이클 created시 호출되는 메서드 */
      createdData() {
        vm = this;

        this.initCodeMap().then(() => {
          this.codes.vacationTypePtCd.dataSource = this.$_fetchCodesByDepth(this.codeMap['root_ewm_hr_vacation_division'], 2);
          this.codes.vacationTypeCd.dataSource = this.$_fetchCodesByDepth(this.codeMap['root_ewm_hr_vacation_division'], 3);

          this.$refs.personalVacationGrid.getGridInstance.columnOption('vacationTypePtCd', 'lookup', this.codes.vacationTypePtCd);
          this.$refs.personalVacationGrid.getGridInstance.columnOption('vacationTypeCd', 'lookup', this.codes.vacationTypeCd);
        });

        this.selectCountList();
      },
      /** @description : 라이프사이클 mounted시 호출되는 메서드 */
      mountData() {
        this.selectDataList();
      },
    },
    created() {
      this.createdData();
    },
    mounted() {
      this.mountData();
    },
  };
</script>
